//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import ReadyUploadSource from '@/components/readyUploadSource';
import RichText from '@/components/richText';
import Preservation from '@/components/preservation';
export default {
  components: {
    ReadyUploadSource,
    RichText,
    Preservation,
  },
  data() {
    return {
      step: '1',
      serviceClassify: [],
      addForm: {
        classify_id: [],
        sort: '',
        name: '',
        picture: [],
        details: '',
        ratio: '',
        status: 1,
        content: [
          {
            title: '',
            is_error: !0,
            detail: [
              {
                content: '',
              },
              {
                content: '',
              },
            ],
          },
        ],
      },
      rules: {
        classify_id: {
          type: 'array',
          required: true,
          message: '请选择服务分类',
          trigger: 'blur',
        },
        picture: {
          type: 'array',
          required: true,
          message: '请添加服务图片',
          trigger: 'blur',
        },
        name: {
          required: true,
          message: '请填写服务名称',
          trigger: 'blur',
        },
        details: {
          required: true,
          message: '请填写服务详情',
          trigger: 'blur',
        },
      },
    };
  },
  async created() {
    await this.$axios
      .post(this.$api.samecity.service.classifyList, {
        is_show: this.is_show,
        name: this.name,
      })
      .then(res => {
        if (res.code == 0) {
          this.serviceClassify = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
    if (this.$route.query.id) {
      this.addForm.id = this.$route.query.id;
      this.getServiceInfo();
    }
  },
  methods: {
    //获取服务图片
    getServiceImg(val) {
      let arr = [...this.addForm.picture];
      for (let i in val) arr.push(val[i].path);
      this.addForm.picture = arr;
    },
    // 拖动重新赋值数据
    changeOrder(val) {
      this.addForm.picture = val;
    }, 
    // 删除单张图片
    removeImageThis(index) {
      this.addForm.picture.splice(index, 1);
    },
    //添加服务信息
    addService() {
      this.addForm.content.push({
        title: '',
        is_error: !0,
        detail: [
          {
            content: '',
          },
          {
            content: '',
          },
        ],
      });
    },
    //添加服务信息内容
    addServiceContent(i) {
      this.addForm.content[i].detail.push({
        content: '',
      });
    },
    //删除服务信息内容
    removeServiceContent(i, y) {
      this.$confirm('确认要删除服务信息内容吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.addForm.content[i].detail.splice(y, 1);
      });
    },
    //删除服务信息
    removeService(i) {
      this.$confirm('确认要删除服务信息吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.addForm.content.splice(i, 1);
      });
    },
    //校验服务信息表单
    checkServiceInfoForm() {
      let addForm = this.addForm;
      addForm.content.map(item => {
        if (item.title) {
          item.detail.map(now => {
            if (!now.content) {
              item.is_error = !0;
            } else {
              item.is_error = !1;
            }
          });
        } else {
          item.is_error = !0;
        }
      });
    },
    //获取服务详情
    getServiceInfo() {
      this.$axios
        .post(this.$api.samecity.service.serviceInfo, {
          id: this.addForm.id,
        })
        .then(res => {
          if (res.code == 0) {
            let info = res.result;
            let addForm = this.addForm;
            let serviceClassify = this.serviceClassify;
            let classify_id_arr = [];
            let content = [];
            addForm.sort = info.sort || '';
            addForm.name = info.name;
            addForm.picture = info.pictures;
            addForm.details = info.details;
            addForm.ratio = info.ratio;
            addForm.status = info.status;
            //处理服务分类
            for (let i in serviceClassify) {
              if (serviceClassify[i].id == info.classify_id) {
                classify_id_arr.push(info.classify_id);
              }
              for (let y in serviceClassify[i]._child) {
                if (serviceClassify[i]._child[y].id == info.classify_id) {
                  classify_id_arr.push(serviceClassify[i].id, info.classify_id);
                }
              }
            }
            //处理服务信息
            info.contents.map(item => {
              let obj = {
                title: item.title,
                is_error: !1,
                detail: [],
              };
              item.content.map(item => obj.detail.push({ content: item }));
              content.push(obj);
            });
            addForm.content = content;
            addForm.classify_id = classify_id_arr;
          }
        });
    },
    serviceSubmit() {
      this.$refs.form.validate((valid, val) => {
        if (valid) {
          let addForm = this.addForm;
          //判断服务信息是否填写完整
          if (addForm.content.every(item => item.is_error == !1)) {
            addForm = _.cloneDeep(addForm);
            let content = [];
            addForm.content.map(item => {
              let obj = {
                title: item.title,
                content: [],
              };
              item.detail.map(now => obj.content.push(now.content));
              content.push(obj);
            });
            addForm.content = content;
            if (addForm.classify_id.length) {
              addForm.classify_id.length > 1 ? (addForm.classify_id = addForm.classify_id[1]) : (addForm.classify_id = addForm.classify_id[0]);
            }
            this.$axios.post(addForm.id ? this.$api.samecity.service.editService : this.$api.samecity.service.addService, addForm).then(res => {
              if (res.code == 0) {
                this.$message.success(addForm.id ? '编辑成功' : '添加成功');
                this.$router.push('/service/list');
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.step = '1';
          }
        } else {
          if (val.hasOwnProperty('details') && Object.keys(val).length == 1) {
            this.step = '2';
          }
        }
      });
    },
  },
};
